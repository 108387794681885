<template>
  <div class="content">
    <div class="time">{{ time }}</div>
    <div class="date">{{ date }}</div>
    <p class="welcome">欢迎来到字景数科物联网系统平台</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      setTime: "",
      time: "",
      date: "",
      week: ""
    };
  },
  created() {
    // @ts-ignore
    this.setTime = setInterval(() => {
      this.Date();
    }, 1000);
    this.getWeekDate()
  },
  methods: {
    // 获取时间戳
    getWeekDate() {
      var date = new Date();
      var day = date.getDay();
      var names = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    //   console.log(names[day]);
      this.week = names[day]
    },
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
      };

      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      this.time = date.hours + ":" + newminutes;
      this.date = date.year + "年" + newmonth + "月" + newday + "日" + " " + this.week;
    },
  },
  beforeDestroy() {
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: calc(100vh - 100px);
  background: rgb(31, 37, 47);
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  font-family: PingFang SC;
  .time{
    width: 13.5rem;
    height: 4.75rem;
    line-height: 4.75rem;
    font-size: 3.75rem;
    background-color: rgb(255, 255, 255);
    text-align: center;
    margin: 17.25rem auto .625rem;
  }
  .date {
    text-align: center;
    font-size: 1rem;
    color: rgb(255, 255, 255);
}
  .welcome {
    color: rgb(255, 255, 255);
    font-size: 1.875rem;
    text-align: center;
    margin-top: 7.25rem;
    letter-spacing: 2px;
  }
}
</style>
